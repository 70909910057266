import request from '@/utils/request'

// 查询收取硬币列表
export function listReceiveCoin(query) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/receive_coin/list',
    method: 'get',
    params: query
  })
}

// 查询收取硬币详细
export function getReceiveCoin(id) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/receive_coin/' + id,
    method: 'get'
  })
}

// 新增收取硬币
export function addReceiveCoin(data) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/receive_coin',
    method: 'post',
    data: data
  })
}

// 修改收取硬币
export function updateReceiveCoin(data) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/receive_coin',
    method: 'put',
    data: data
  })
}

// 删除收取硬币
export function delReceiveCoin(id) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/receive_coin/' + id,
    method: 'delete'
  })
}
