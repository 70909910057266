<template>
  <div>
    <el-main>
      <search-receive @submit="submitSearch"/>

      <div class="table-btn-list">
        <el-button
            class="table-btn"
            type="primary"
            @click="handleExport"
            :disabled="disabled"
        >
          导出
        </el-button>
      </div>


      <el-table :data="data" border style="width: 100%">
        <el-table-column prop="createTime" label="收取时间" />
        <el-table-column prop="userName" label="收取人" />
        <el-table-column prop="coinCount" label="收取硬币数量" />
        <el-table-column
          prop="accumulateCount"
          label="本次收取后累计收取数量"
          width="180px"
        />
        <el-table-column prop="deviceLocation.deviceName" label="设备名称" />
        <el-table-column prop="deviceLocation.deviceNo" label="设备编号" />
        <el-table-column prop="deviceLocation.project.name" label="项目名称" />
        <el-table-column
          prop="deviceLocation.project.projectType.name"
          label="项目类型"
        />
        <el-table-column
          prop="deviceLocation.project.areaName"
          label="所属区域"
        />
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="query.pageNum"
        :limit.sync="query.pageSize"
        @pagination="fetchData"
      />
    </el-main>
  </div>
</template>

<script>
import { isResOK } from "@/api/response";
import { listReceiveCoin } from "@/api/receive_coin";
import Pagination from "@/components/Pagination/index.vue";
import SearchReceive from "@/view/receive_coin/Search.vue";
import { download } from "@/utils/request";
import { ElMessage } from "element-plus";
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";
export default {
  name: "",
  components: {
    Pagination,
    SearchReceive,
  },
  data() {
    return {
      data: [],
      total: 0,
      query: {
        pageNum: 1,
        pageSize: 10,
      },
      disabled:false
    };
  },
  created() {
    this.fetchData();
    this.fetchCurrentUser()
  },
  methods: {
    download,
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },

    fetchData() {
      listReceiveCoin(Object.assign({}, this.query))
        .then((res) => {
          if (isResOK(res)) {
            this.data = res.rows;
            this.total = res.total;
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
      this.total = 0;
    },
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({}, { pageNum: this.query.pageNum }, search);
      this.fetchData();
      this.disabled = false
    },

    handleExport() {
      if (this.disabled) {
        this.$message({
          message: '已经导出，无需重复导出',
          type: 'warning',
        })
        return
      }
      this.disabled=false
      if (this.data.length>0) {
        const searchForm = Object.assign({}, this.query);
        this.download(
          process.env.VUE_APP_BASE_API + "business/receive_coin/export",
          {
            ...searchForm,
          },
          `收币记录.xlsx`
        );
        this.disabled=true
      } else {
        ElMessage({
          message: "数据为空不可导出",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style scoped></style>
