<template>
  <div>
    <el-form ref="formRef" :inline="true" :model="formState" label-position="left" label-width="85px">
      <el-row>
        <el-col :span="6">
          <el-form-item prop="deviceNo" label="设备编号">
            <el-input
                v-model="formState.deviceNo"
                placeholder="请输入设备编号"
                clearable
                style="width: 240px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="deviceName" label="设备名称">
            <el-input
                v-model="formState.deviceName"
                placeholder="请输入设备名称"
                clearable
                style="width: 240px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="name" label="项目名称">
            <el-input
                v-model="formState.name"
                placeholder="请输入项目名称"
                clearable
                style="width: 240px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="projectTypeId" label="项目类型">
            <el-select
                style="width: 240px"
                v-model="formState.projectTypeId"
                filterable
                clearable
                placeholder="请选择项目类型"
            >
              <el-option
                  v-for="item in productTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="areaId" label="选择区域">
            <el-cascader
                :key="cascaderKey"
                v-model="areaId"
                :options="areaOptions"
                placeholder="请选择区域"
                :props="{
            value: 'orgId',
            label: 'orgName',
            children: 'subOrgList',
            checkStrictly: true,
          }"
                @change="handleChangeArea"
                :disabled="disabled"
                clearable
                style="width: 240px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="queryTime" label="收币时间">
            <el-date-picker
                v-model="formState.queryTime"
                type="date"
                placeholder="请选择收币时间"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                mini="2020-01-01"
                style="width: 240px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="handleSearch">搜索</el-button>
          <el-button type="warning" @click="handleReset">刷新</el-button>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>

<script>
import {getProjectTypeOptions} from "@/api/project_type";
import {isResOK, isSysResOK} from "@/api/response";
import {getProjectStatusOptions} from "@/api/project";
import {queryOrgRelTree} from "@/api/system";
import {areaOptions} from "@/api/mock";

export default {
  name: "SearchProject",
  emits: ["submit", "export"],
  data() {
    return {
      formState: {},
      productTypeOptions: [],
      productStatusOptions: [],
      areaOptions: [],
      areaId: [],
      disabled: false,
      cascaderKey: 0,
    };
  },
  created() {
    this.getProductTypeOptions();
    this.getProjectStatusOptions();
    this.queryOrgRelTree();
  },
  methods: {
    handleChangeArea(val) {
      if (val !== null && val !== undefined) {
        this.formState.areaId = this.areaId;
      } else {
        this.formState.areaId = undefined;
      }
    },
    queryOrgRelTree() {
      queryOrgRelTree({
        orgId: "1681542405985234945",
        relType: "1",
      }).then((res) => {
        if (this.areaOptions) {
          this.disabled = true;
        }
        if (isSysResOK(res)) {
          this.areaOptions.push(res.result.orgRelInfoVo);
          this.disabled = false;
        }
      });
    },
    getProductTypeOptions() {
      getProjectTypeOptions().then((res) => {
        if (isResOK(res)) {
          this.productTypeOptions = res.data;
        }
      });
    },
    getProjectStatusOptions() {
      getProjectStatusOptions().then((res) => {
        if (isResOK(res)) {
          this.productStatusOptions = res.data;
        }
      });
    },
    handleSearch() {
      this.$emit("submit", this.formState);
    },

    handleReset() {
      this.$refs.formRef.resetFields();
      this.areaId = undefined;
      this.cascaderKey = new Date().getTime()
      this.$emit("submit", this.formState);
      this.disabled = true;
    },
  },
};
</script>
<style scoped>
.el-form-item {
  margin-right: 15px;
}

.el-button {
  margin-bottom: 18px;
}
</style>
